import { useEffect, useState } from "react";
import logo from "../../assets/logo/sec2__logo.png";
import Mobile from "./Mobile";
import Nav from "./Nav";

const Navbar = () => {
  const [bg, setBg] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      return window.scrollY > 20 ? setBg(true) : setBg(false);
    });
  });
  return (
    <div
      className={`${bg ? "bg-white h-20 border-b-2  " : "h-24"}
       flex items-center lg:justify-center justify-between md:justify-between w-full p-10 transition all duration-300 fixedContainer   `}
    >
      <div className="flex w-[100px] lg:hidden  ">
        <img className=" md:w-auto h-auto cursor-pointer" src={logo} alt="" />
      </div>
      <div className="hidden lg:flex  md:items-center justify-between w-[85%]  ">
        {/* w-full */}
        <img
          className=" md:w-[10%] lg:w-[150px] h-auto cursor-pointer"
          src={logo}
          alt=""
        />
        <Nav />
      </div>
      <div className=" lg:hidden pl-10">
        <Mobile />
      </div>
    </div>
  );
};
export default Navbar;
