import React from "react"
// import NoIconslider from "../ui/carousel/NoIconslider"
import { partnerDatas } from "../../data"
const Partners = () => {
  return (
    <div
      id='Partners'
      className='overflow-hidden w-full flex flex-col items-center  justify-center  py-24 p-10 md:h-[256px] h-[435px] md:pt-40'
    >
      <div className=' w-full  flex flex-col md:flex-row items-center md:gap-60 gap-10  justify-center '>
        <div>
          <h1 className='text-3xl font-semibold lg:font-bold lg:text-4xl '>
            Partners
          </h1>
        </div>
        <div className=' flex flex-col md:flex-row items-center gap-10'>
          {partnerDatas.map((item) => {
            return (
              <div key={item.id} className=' '>
                <img className='md:w-[300px] w-auto' src={item.image} alt='' />
              </div>
            )
          })}
        </div>

        {/* mobile */}
        {/* <div id='Partners' className='hidden md:flex'>
          <NoIconslider />
        </div> */}
      </div>
    </div>
  )
}

export default Partners
