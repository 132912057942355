import React from "react"
import Adrverts from "./components/advert"
import ComingSoon from "./components/comingSoon/ComingSoon"
import Footer from "./components/footer.jsx/Footer"
import Navbar from "./components/navbar/Navbar"
import Opus from "./components/opus"
import OurMission from "./components/ourMission/OurMission"
import Partners from "./components/partners"
import SectionFour from "./components/sectionFour"
import SectionThree from "./components/sectionThree"
import BackToTopBtn from "./components/ui/backToTop/BackToTop"

const Root = () => {
  return (
    <div>
      <Navbar />
      <OurMission />
      <Adrverts />
      <Opus />
      <SectionThree />
      <SectionFour />
      <ComingSoon />
      <Partners />
      <Footer />
      <BackToTopBtn />
    </div>
  )
}

export default Root
