import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import img from "../../assets/img/2.png";
import img2 from "../../assets/img/2.png";

const SectionThree = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div
      className="bg-[#FBF6F4]  h-[1100px] flex flex-col md:flex-row 
     items-center justify-center lg:gap-48 gap-20 md:gap-2   md:px-10  w-full  "
    >
      <div data-aos="fade-up" className="md:hidden flex ">
        <img src={img2} alt="" className="w-full" />
      </div>
      <div className="flex flex-col gap-4 md:text-right ">
        <h5 data-aos="fade-up" className="font-medium text-primary">
          {" "}
          IP 투자
        </h5>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          즐기기만 하던
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          게임, 영화 IP
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          직접 투자하기
        </h1>
        <p className="text-base font-light md:text-lg md:font-medium pt-2">
          안목있는 당신, 생각지도 못했던 <br />
          멋진 콘텐츠들의 초기 투자자가 되어보세요. <br /> 좋아하는 게임, 영화
          프로젝트에 투자하기 <br /> 유니콘 프로젝트의 초기부터 성공까지 함께
        </p>
      </div>

      <div data-aos="fade-up" className="hidden pl-10  md:flex">
        <img src={img} alt="" className="w-full" />
      </div>
    </div>
  );
};

export default SectionThree;
