import React from "react"

const OurMission = () => {
  return (
    <div
      id='ourMission'
      className='bg-[#FBF6F4] bg-bg_1 h-[145vh] mbl:h-[110vh] sm:h-[100vh] md:h-[80vh]  bg-auto bg-no-repeat bg-bottom 
       overflow-hidden flex items-start justify-center md:justify-start md:items-center md:pl-20 lg:pl-80 w-full md:pt-10 pt-20      '
    >
      <div className='flex items-center flex-col md:items-start gap-6'>
        <h1
          className='font-bold text-5xl md:text-7xl
        '
        >
          모두를 위한
        </h1>
        <h1
          className='font-bold text-5xl md:text-[68px]
        '
        >
          조각투자
        </h1>
        <p className='pt-7 text-xl  md:text-2xl font-light'>
          다양한 포트폴리오 구성까지
        </p>
        <p className=' text-xl  md:text-2xl font-light'>
          한 번에
          <span className='text-primary font-bold'> 오퍼스</span>에서 바로
        </p>
      </div>
    </div>
  )
}
export default OurMission
