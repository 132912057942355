import first from "./assets/partners/first.svg";
import sec from "./assets/partners/sec.svg";
import third from "./assets/partners/third.svg";

import {
  // FiYoutube,
  FiInstagram,
  FiLinkedin,
  FiFacebook,
  FiTwitter,
} from "react-icons/fi";
export const navigation = [
  {
    name: "Highlights",
    href: "Highlights",
  },
  {
    name: "Download",

    href: "Download",
  },

  {
    name: "Partners",
    // href: "Contact",
    href: "Partners",
  },
  {
    name: "Contact",
    href: "Contact",
  },
];

export const social = [
  /* {
    icon: <FiFacebook />,
    href: "",
  }, */
  {
    icon: <FiInstagram />,
    href: "https://www.instagram.com/opusprotocol",
  },
  /* {
    icon: <FiTwitter />,
    href: "",
  }, */

  {
    icon: <FiLinkedin />,
    href: "https://www.linkedin.com/company/lowellpef",
  },
];
export const partnerDatas = [
  {
    id: "1",
    image: first,

    href: "",
  },
  {
    id: "4",
    image: sec,

    href: "",
  },

  {
    id: "3",
    image: third,

    href: "",
  },
];
export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 5,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
