import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { social } from "../../data";
import { navigation } from "../../data";
import { Link } from "react-scroll";
import logo from "../../assets/logo/sec2__logo.png";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div
      className=" flex  flex-col items-center justify-center   w-full h-[573px] lg:h-[100vh] "
      id="Contact"
    >
      <div
        data-aos="fade-up"
        className="w-full flex flex-col lg:flex-row  items-center justify-center lg:gap-48  p-10 gap-64 "
      >
        <div className="flex flex-col gap-6 items-start">
          <div>
            <div>
              <img src={logo} alt="" className="w-[100px]" />
            </div>

            <h5 className="font-bold pt-6">Adress:</h5>
            <p>
              2nd floor, 11, dosan-daero 90-gil, Gangnam-gu, Seoul
              (Cheongdam-dong)
            </p>
          </div>
          <div>
            <h5 className="font-bold">Contact:</h5>
            <a href="tel: +82-2-518-2251">
              <p className="btn hover:opacity-75">82-2-518-2251 </p>
            </a>
            <a href=" mailto: admin@lowellpef.com">
              <p className="btn hover:opacity-75">admin@lowellpef.com</p>
            </a>
          </div>
          <div className="flex space-x-6 items-center justify-center ">
            {social.map((item, index) => {
              const { href, icon } = item;
              return (
                <a
                  data-aos="fade-up"
                  className="text-primary text-base btn pb-2 hover:opacity-75 social  "
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  {icon}
                </a>
              );
            })}
          </div>
          <div className="">
            <p className="lg:hidden pb-4 ">
              법인회원으로 가입을 원하는 경우, 법인 고객 상담팀
              <span className="font-medium"> (admin@lowellpef.com)</span> 으로
              요청주시면 도움 드리겠습니다.
            </p>
            <p>
              &copy; 2022-{new Date().getFullYear()} Lowell Private Equity. All
              rights reserved.
            </p>
            <p className="hidden lg:flex">
              법인회원으로 가입을 원하는 경우, 법인 고객 상담팀
              <span className="font-medium btn hover:opacity-75">
                (admin@lowellpef.com)
              </span>
              으로 요청주시면 도움 드리겠습니다.
            </p>
          </div>
          {/* <div className="lg:hidden flex items-center justify-center gap-4 pt-2 ">
            <p className="btn hover:opacity-75">Privacy Policy</p>
            <p className="btn hover:opacity-75">Terms of Service</p>
          </div> */}
        </div>
        <div className="hidden lg:flex items-end  justify-center gap-4 md:flex-col">
          <div className="">
            <h5 data-aos="fade-up">OPUS</h5>
            <ul className="flex justify-center flex-col ">
              {navigation.map((item, index) => {
                return (
                  <li
                    data-aos="fade-right"
                    className={` btn border-transparent cursor-pointer  py-2 `}
                    key={index}
                  >
                    <Link
                      to={item.href}
                      // activeClass='active'
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-70}
                      className="transition-all duration-300"
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className='flex items-center justify-center gap-4 pt-24 '>
            <p className='btn hover:opacity-75'>Privacy Policy</p>
            <p className='btn hover:opacity-75'>Terms of Service</p>
          </div> */}
        </div>
      </div>
      <div className=" flex flex-col md:flex-row md:items-center justify-center gap-4 lg:gap-48 items-start md:gap-40 p-10">
        {/*  */}
      </div>
    </div>
  );
};

export default Footer;
