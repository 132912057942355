import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
// import { AiOutlineArrowUp } from "react-icons/ai"
import icon from "../../../assets/logo/icon.svg";
const BackToTopBtn = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      return window.scrollY > 300 ? setShow(true) : setShow(false);
    });
  });
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    show && (
      <button
        onClick={() => scrollToTop()}
        className="shadow social  rounded-full bg-white text-white border-2 p-2 hover:opacity-75
            fixed right-5 bottom-5 md:right-24 md:bottom-24 cursor-pointer flex justify-center items-center transition-all active:opacity-70"
      >
        {/* <AiOutlineArrowUp className='w-4 h-4' /> */}
        <img src={icon} alt="" className="w-10" />
      </button>
    )
  );
};

export default BackToTopBtn;
