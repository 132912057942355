import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
// import img from "../../assets/img/s1.svg"
import imgMobile from "../../assets/img/1.png";
// import Image from "./Image"

import main from "../../assets/img/1.png";
import "./style.css";

const Opus = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div
      className=" md:relative  bg-[#FBF6F4] justify-center lg:gap-48 md:gap-2  h-[1100px]  
      flex flex-col md:flex-row items-center px-0   md:px-10 w-full "
      id="realEstate"
    >
      <div className="hidden md:absolute top-0 bottom-0 left-0 right-0 lg:top-0 md:bottom-0 md:left-0 md:right-0 bg-[rgba(0, 0, 0, 0.7);]"></div>
      <div className="hidden md:flex md:absolute bottom-0 left-[5%] right-0 top-[10%] ] md:top-[25%] md:bottom-0 md:left-16  md:gap-5 lg:left-[20%]  flex-col text-white">
        <div className="hidden md:flex card-previews ">
          <div data-aos="fade-up" className="card-front ">
            <img
              className="card-front__bg  w-[300px] md:w-[330px]   lg:w-auto "
              src={main}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* <div className='hidden  md:flex  '>
        <img src={img} alt='' className='w-full' />
      </div> */}
      <div className="md:hidden  flex ">
        <img src={imgMobile} alt="" className="w-full px-4" />
      </div>
      <div className="hidden md:absolute top-0 bottom-0 left-0 right-0 lg:top-0 md:bottom-0 md:left-0 md:right-0 bg-[rgba(0, 0, 0, 0.7);]"></div>
      <div className="hidden md:absolute bottom-0 left-10 right-0 top-[50%] md:top-[25%] md:bottom-0 md:left-[60%] lg:left-[45%]  lg:top-[35%]  md:flex md:gap-5 ">
        <div className="flex flex-col gap-6  lg:pl-72">
          <h5
            data-aos="fade-up"
            className=" text-primary text-base font-medium md:text-lg md:font-medium "
          >
            부동산
          </h5>
          <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
            정기적으로
          </h1>
          <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
            월세 배당금을
          </h1>
          <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
            받아보세요.
          </h1>
          <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
            매각차익은 덤.
          </h1>
          <div>
            <p
              data-aos="fade-up   "
              className="text-base pt-4 font-light md:text-lg md:font-medium"
            >
              이율은 높게, 관리 부담은 적게
            </p>
            <p
              data-aos="fade-up  "
              className="text-base font-light md:text-lg md:font-medium"
            >
              매각,운용보수를 동시에 받을 수 있는
            </p>
            <p
              data-aos="fade-up  "
              className="text-base font-light md:text-lg md:font-medium"
            >
              부동산 조각 투자하기
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full items-start px-14  md:hidden flex-col gap-3  lg:pl-72 ">
        <h5
          data-aos="fade-up"
          className="font-medium text-primary text-base  md:text-lg md:font-semibold "
        >
          부동산
        </h5>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          정기적으로
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          월세 배당금을
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          받아보세요.
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          매각차익은 덤.
        </h1>
        <div>
          <p
            data-aos="fade-up   "
            className="text-base pt-2 font-light md:text-lg md:font-medium"
          >
            이율은 높게, 관리 부담은 적게
          </p>
          <p
            data-aos="fade-up  "
            className="text-base font-light md:text-lg md:font-medium"
          >
            매각,운용보수를 동시에 받을 수 있는
          </p>
          <p
            data-aos="fade-up  "
            className="text-base font-light md:text-lg md:font-medium"
          >
            부동산 조각 투자하기
          </p>
        </div>
      </div>
    </div>
  );
};

export default Opus;
