import React from "react";

const Adrverts = () => {
  return (
    <div
      id="Highlights"
      className=" bg-[#FBF6F4] ] md:bg-white h-[667px] flex  items-center justify-center  flex-col  gap-2 mbl:py-0  px-10 w-full "
    >
      <h1 className="text-4xl font-normal md:text-6xl md:font-normal ">
        어려운 투자는
      </h1>
      <h1 className="text-4xl font-normal md:text-6xl md:font-normal ">
        {" "}
        <span className="text-primary font-semibold ">오퍼스</span>와 쉽게
      </h1>
      <ul className="list-disc flex items-center flex-col gap-4 pt-7">
        <li className="text-base font-medium md:text-lg md:font-semibold ">
          실물자산도 주식처럼 쉽게 거래하는 수익증권ST
        </li>
        <li className="text-base font-medium md:text-lg md:font-semibold ">
          안정적인 부동산부터 매력적인 IP까지
        </li>
        <li className="text-base font-medium md:text-lg md:font-semibold ">
          한번에 비교해보고 다양하게 분산 투자해보세요.
        </li>
      </ul>
    </div>
  );
};

export default Adrverts;
