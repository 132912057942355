import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
// import { AiOutlineDownload } from "react-icons/ai"
import img from "../../assets/img/button.png";
import logo from "../../assets/logo/logo2.png";
import phone from "../../assets/img/phone2.png";

const ComingSoon = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div
      className="bg-[#FF571F] h-[1000px] md:h-[900px] flex flex-col sm:flex-row items-center justify-between md:justify-center gap-4 lg:gap-60   py-24  "
      id="Download"
    >
      <div className="flex flex-col items-start gap-4 md:gap-6 pl-14   ">
        <div>
          <img src={logo} alt="" className=" md:w-[200px] w-[150px]" />
        </div>

        <h1
          data-aos="fade-up"
          className=" flex md:hidden uppercase text-white text-4xl font-medium md:text-6xl md:font-black "
        >
          Download Coming Soon
        </h1>
        <h1
          data-aos="fade-up"
          className="hidden md:flex uppercase text-white text-3xl font-medium md:text-6xl md:font-black"
        >
          Download
        </h1>
        <h1 className="hidden md:flex uppercase text-white text-3xl font-medium md:text-6xl md:font-black">
          Coming
        </h1>
        <h1 className="hidden md:flex uppercase text-white text-3xl font-medium md:text-6xl md:font-black">
          Soon
        </h1>

        <div data-aos="fade-up" className="cursor-pointer  active:opacity-75">
          <img src={img} alt="" className=" w-[150px] md:w-[250px]" />
        </div>
      </div>
      {/*  */}
      <div data-aos="fade-up" className=" ">
        <img src={phone} alt="" className=" w-[250px] md:w-[400px]" />
      </div>
    </div>
  );
};

export default ComingSoon;
