import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import img from "../../assets/img/3.png";
import img2 from "../../assets/img/3.png";

const SectionFour = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <div className=" bg-[#FBF6F4]  h-[1100px] flex flex-col md:flex-row items-center justify-center lg:gap-48    md:px-10 ">
      <div data-aos="fade-up" className="hidden  md:flex  ">
        <img src={img} alt="" className="w-full" />
      </div>
      <div data-aos="fade-up" className="md:hidden  flex  ">
        <img src={img2} alt="" className="w-full" />
      </div>
      <div className="flex flex-col gap-4 py-24">
        <h5 data-aos="fade-up" className="font-medium text-primary">
          {" "}
          동산 투자
        </h5>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          나도 할 수 있는
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          대형 건설프로젝트,
        </h1>
        <h1 data-aos="fade-up" className="font-bold text-3xl md:text-5xl">
          선박 투자
        </h1>
        <p
          data-aos="fade-up"
          className="text-base font-light md:text-lg md:font-medium"
        >
          투자금액이 많이 필요해 접근성이 높았던 <br /> 대형 투자상품의 허들을
          낮췄어요.
          <br /> 누구나 소액으로 할 수 있는 동산 투자
        </p>
      </div>
    </div>
  );
};

export default SectionFour;
