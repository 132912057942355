import React from "react"
import { navigation } from "../../data"
import { Link } from "react-scroll"
const Nav = () => {
  return (
    <div>
      <ul className='flex items-center justify-center gap-10 '>
        {navigation.map((item, index) => {
          return (
            <li
              className={` btn border-transparent border-b-2 cursor-pointer flex flex-row  py-6  font-bold text-lg`}
              key={index}
            >
              <Link
                to={item.href}
                activeClass='active'
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                className='transition-all duration-300'
              >
                {item.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Nav
//hover:border-[#f75f23]
